<template>
 <div :class="windowClass">
   <v-container>
    <v-row no-gutters >
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                      <span class="text-h5 font-weight-bold">{{Adults.length}}</span>
                      <div class="text-overline"><v-icon  small left color="success">mdi-family-tree</v-icon>Adults</div>
                </v-card>
      </v-col>
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                      <span class="text-h5 font-weight-bold">{{Seniors.length}}</span>
                      <div class="text-overline"><v-icon small left color="success">mdi-account-credit-card-outline</v-icon>SENIOR CITIZEN</div>
                </v-card>
      </v-col>
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                      <span class="text-h5 font-weight-bold">{{Employed.length}}</span>
                      <div class="text-overline"><v-icon small left color="success">mdi-account-hard-hat-outline</v-icon>EMPLOYED</div>
                </v-card>
      </v-col>
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                  <span class="text-h5 font-weight-bold">{{SKs.length}}</span>
                  <div class="text-overline"><v-icon small left color="success">mdi-family-tree</v-icon>SK MEMBERS</div>
                </v-card>
      </v-col>
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                  <span class="text-h5 font-weight-bold">{{SKs.length}}</span>
                  <div class="text-overline"><v-icon small left color="success">mdi-family-tree</v-icon>4P`s MEMBERS</div>
                </v-card>
      </v-col>
      <v-col cols="6" sm="3">
                <v-card class="text-center pa-2 ma-1">
                  <span class="text-h5 font-weight-bold">{{SKs.length}}</span>
                  <div class="text-overline"><v-icon small left color="success">mdi-family-tree</v-icon>PH MEMBERS</div>
                </v-card>
      </v-col>
 
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-toolbar dense elevation="1">
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> Data
                 <v-spacer/>
               
                 <v-divider vertical inset class="ml-2 mr-2"/>
                          <v-btn  color="success"  @click="$router.push('/data-sheet/0').catch(err => {})"><v-icon left>mdi-plus</v-icon> New DATA</v-btn>
               </v-toolbar>

               <v-toolbar dense elevation="0" class="mt-4 mb-4" >
                <span style="width: 200px;" class="mr-2">
                 <v-select
                 outlined
                 label="Filter"
                 dense
                 hide-details
                 v-model="filterText"
                 :items="['All', 'Adults', 'Senior Citizen', 'SK Members', 'Employed', 'Unemployed',  '4P Members',  'Phil Health Members']"
                 />
                 </span>
                 <span style="width: 100%;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
               </v-toolbar> 
               
               <v-divider class="mt-4 mb-4"/>
               <v-data-table
                    class="va-table text-body-2"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="FILTERDATA"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td class="text-left">{{ item.surname }}</td>
                            <td class="text-left">{{ item.first_name }}</td>
                            <td class="text-left">{{ item.gender }}</td>
                            <!-- <td class="text-left">{{ item.civil_status }}</td> -->
                            <!-- <td class="text-left">{{ item.position }}</td> -->
                            <!-- <td class="text-left">{{ item.address }}</td> -->
                            <td class="text-left">{{ remarks(item) }}</td>
                            <td class="text-right"><v-btn x-small text color="success" @click="$router.push('/data-sheet/'+item.id).catch(err => {})">View</v-btn></td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
                    <p>Filter: {{filterText}} | {{FILTERDATA.length}} Found(s)</p>
      </v-col>
    </v-row>
    </v-container>
    <va-census-form :show="form" @DialogEvent="formEv"/>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        search: '',
        items:[],
        headers: [
          { text: 'Surname', value:"surname", align: 'left'},
          { text: 'First Name', value:"first_name" , align: 'left'},
          { text: 'Gender', value:"gender" , align: 'left'},
          // { text: 'Civil Status', value:"civil_status" , align: 'left'},
          // { text: 'Position', value:"position" , align: 'left'},
          // { text: 'Address', value:"address" , align: 'left'},
          { text: 'Remarks' , align: 'left'},
          { text: '--', value:"--", align: 'right'}
        ],
        filterText: "All"
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
          this.filterText = "All"
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      FILTERDATA() {
        //['All', 'Adults', 'Senior Citizen', 'SK Members', 'Employed', 'Unemployed']
        switch(this.filterText) {
            case 'Adults':
              return this.Adults
            case 'Senior Citizen':
              return this.Seniors
            case 'SK Members':
              return this.SKs
            case 'Employed':
              return this.Employed
            case 'Unemployed':
              return this.Unemployed
            case 'Phil Health Members':
              return this.PhMember  
            case '4P Members':
              return this.PPPPMember      
            default:
                return this.items
        }
      },
      Adults() {
        var filters = this.items.reduce((res,item)=>{
            if("Adults" == item.category) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      Seniors() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.senior) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      PhMember() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.ph_member) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      PPPPMember() {
        var filters = this.items.reduce((res,item)=>{
            if("yes" == item.pppp_member) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      SKs() {
        var filters = this.items.reduce((res,item)=>{
            if("SK" == item.category) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      Employed() {
        var filters = this.items.reduce((res,item)=>{
            if("Employed" == item.employment) {
              res.push(item)
            }
            return res
        }, [])
        return filters
      },
      Unemployed() {
        var filters = this.items.reduce((res,item)=>{
            if("Unemployed" == item.employment) {
              res.push(item)
            }
            return res
        }, [])
        console.log(filters)
        return filters
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      formEv(){
        this.form = false
      },
      remarks(data){
          if(data.senior == 'yes') {
            return "Senior Citizen"
          } else if(data.student == 'yes') {
            return "Student"
          } else if(data.pwd == 'yes') {
            return "PWD"
          } else {
            return "----"
          }

      },
      getItems() {
        this.$http.get("data/list").then(response => {
                    response.data.items != null?this.items = response.data.items:this.items=[]
                }).catch(e => {
                 console.log(e.data)
             })
      }
  }
} 
</script>